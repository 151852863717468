.contenedor_joinus{
    min-height: 100vh;
    background-size: cover;   
    background-position: center center; 
    background-color: rgb(173, 216, 230);  
 
 
}


.imagenesTam{
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imagenesTam1{
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}


.container_bannerus{
    height: 50vh;
    width: 100%;
    position: relative;
  
  }
  
  .container_bannerus img{
    height: 100%;
    width: 100%;
  
  }
  
  .container_bannerus p{
    position: absolute;
    color: white;
    top: 35%;
    right: 10%;
    transform: translate(-0%, -0%);
    font-size: 140%;
  }
  

  .container_bannerus1{
    height: 50vh;
    width: 100%;
    position: relative;
  
  }
  
  .container_bannerus1 img{
    height: 100%;
    width: 100%;
  
  }
  
  .container_bannerus1 p{
    position: absolute;
    color: white;
    top: 35%;
    right: 10%;
    transform: translate(-0%, -0%);
    font-size: 140%;
  }