.contenedor_registro{
    background-color:lightblue;
    background-size: cover;   
    background-position: center center;
   
    

}


#encabezado{
    background-color: rgb(0, 100, 162);
    
}


#registro{
    width: 100%;
    max-width: 600px; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: LightSteelBlue;
}

ul {
    list-style: none;
}

/*Formulario*/

.formRegister {
    display: flex;
    flex-direction: column;
}

.centerDiv {
    /* align-items: center; */
    /* background-color: red; */
    justify-content: space-between;
}

.textError{
    color:red
}
.textValid{
    color:#57B020
}


.container-main
{
    width: 40%;
    margin: 0 auto;

}

.sugerencia{
    padding: 5px;
}


.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 31px;
    width: 500px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    max-height: 300px;
    overflow: auto;
    margin-top: 16px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.react-autosuggest__suggestions-list{
    list-style: none;
    margin-bottom: 0px;
    padding-left: 0px;
    cursor: pointer;
}

.react-autosuggest__input{
    width: 570px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}

