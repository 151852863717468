.contenedor_joinus{
    min-height: 100vh;
    background-size: cover;   
    background-position: center center; 
    background-color: rgb(173, 216, 230);  
}


.imagenesTam{
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.imagenesTam1{
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

