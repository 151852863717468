.container_aboutus{
    min-height: 100vh;
      background-size: cover;   
      background-position: center center; 
      background-color: rgb(173, 216, 230);  
  
  }
  
  
  
  #padding{
    padding-left:4%;
    padding-right: 4%;
  
  }
  
  .imagenesTamAb{
    width: 50%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .imagenesTamAb2{
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
  }
  
  .imagenesTamAb3{
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  #azul{
    color: rgb(0, 179, 255);
  }
  
  #rojo{
    color: rgb(224, 11, 11);
  }
  
  #colorred{
    color:brown;
  }
  
  #imagenajustada{
    width: 100%;
    max-width: 1200px;
    max-height: 400px;


}

.container_bannernos{
  height: 50vh;
  width: 100%;
  position: relative;

}

.container_bannernos img{
  height: 100%;
  width: 100%;

}

.container_bannernos p{
  position: absolute;
  color: white;
  top: 35%;
  right: 67%;
  transform: translate(-0%, -0%);
  font-size: 140%;
}

.container_bannernos2{
  height: 50vh;
  width: 100%;
  position: relative;

}

.container_bannernos2 img{
  height: 100%;
  width: 100%;

}

.container_bannernos2 p{
  position: absolute;
  color: white;
  top: 35%;
  right: 67%;
  transform: translate(-0%, -0%);
  font-size: 140%;
}

