.container_home{
    background-image: url("../../assets/fondo1.jpg");
    height: 100%;
    background-size: cover;   
    background-position: center center;    
 
}


#paginaprincipal{
    padding-left:4%;
    padding-right: 4%;
    
}

#color{
    color: rgb(0, 100, 162);
}

#margin{
    margin: 0;
    padding: 0;
}


#titulo{
    color: rgb(0, 100, 162);
    
}

.columnnas{
    display: flex;
    justify-content: right;
}

#inputidioma{
    width: 65px;
    
}

.container_banner{
    height: 50vh;
    width: 100%;
    position: relative;

}

.container_banner img{
    height: 100%;
    width: 100%;

}

.container_banner p{
    position: absolute;
    color: white;
    top: 75%;
    right: 5%;
    transform: translate(-0%, -0%);
    font-size: 280%;
}

