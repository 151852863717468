.contenedor_sesion{
    min-height: 100vh; 
    background-image: url("../../assets/fondo2.jpg");
    background-size: cover;   
    background-position: center center;    
}


#encabezado{
    background-color: rgb(0, 100, 162);
}





#registroLoggin{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.text{
    font-size: small;
}

.password{
        font-size: small;
}

.mingga{
    color: dodgerblue;
}

.welcome{
    font-size:x-large;
}

.textError{
    color:red
}
